@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
    font-family: 'Lexend';
    font-weight: 900;
    src: local('Lexend'), url(../public/assets/fonts/Lexend-Regular.ttf) format('opentype');
  }
  
  @font-face {
      font-family: 'Lexend';
      font-weight: 900;
      src: local('Lexend'), url(../public/assets/fonts/Lexend-Regular.ttf) format('opentype');
  }
  
  @font-face {
      font-family: 'Lexend';
      font-weight: 900;
      src: local('Lexend'), url(../public/assets/fonts/Lexend-Regular.ttf) format('opentype');
  }