.header_icon{
    object-fit: contain;
    height: 120px;
    margin: 0px;
}
.header_center{
    display:flex;
    flex:1;
    align-items: center;
    height: 30px;
    max-width: fit-content;
    border: 1px solid lightgray;
    border-radius: 999px;
    padding:10px;
}
.header_center>input{
    border:none;
    padding:0px;
    outline-width: 0;
}

/* .register-btn {
    margin-left : 2px;
} */

/* .login-btn {
    margin-right: 3px !important;
    margin-left: 10%;
} */



.topLeft,.topRight{
    flex:3;
    display: flex;
    align-items: center;
    justify-content: center;
    
}
.topCenter{
    flex:6;
}
.topImg{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}
.justify-content-end.flex-grow-1.pe-3.navbar-nav{
    display: flex;
    align-items: center;
    justify-content: center;
}
a.dropdown-toggle.nav-link{
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.topIcon{
    font-size: 20px;
    margin-right: 10px;
    color:#444;
    cursor: pointer;
}
.topList{
    display: flex;
    justify-content: center;
    margin: 0;
    padding:0;
    list-style: none;
}

.topListItem{
    margin-right: 20px;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.active {
    color: #1185d2 !important;
    font-weight: 500px;
}

.topSearchIcon{
    font-size: 18px;
    color:#666;
    cursor: pointer;
    margin-left: 15px;
}

.search-box {
    position: absolute;
    top : 50%;
    right: 10px;
    transform: translate(0%, -50%);
    background: #2f3640;
    height: 40px;
    border-radius: 40px;
  }
  
  .search-box:hover > .search-text{
    width: 240px;
    padding: 0 6px;
  }
  
  .search-box:hover > .search-btn{
    
    color: white;
    
  }
  
  .search-btn {
    color: white;
    float: right;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #2f3640;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.4s;
    cursor: pointer;
    text-decoration: none;
    
  }
  .search-btn > i {
    fontsize: 30px;
  }
  
  .search-text {
    border: none;
    background: none;
    outline: none;
    float: left;
    padding: 0;
    color: white;
    font-size: 16px;
    font-weight: normal;
    transition: 0.4s;
    line-height: 40px;
    width: 0px;
    
  /*   width: 250px; */
  }

  .topbar {
    font-weight:500px;
    height: 75px;   
  }
  .dropdownprofile{
      align-items: center;
  }