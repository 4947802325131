.sidebar{
    flex:3;
    padding-bottom: 30px;
    background-color: #fdfbfb;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: fit-content;
}

.sidebarItem{
    display: flex;
    flex-direction: column;
    align-items: center; 
    text-align: center;
}

.sidebarTitle{
    margin: 10px;
    padding: 5px;
    width: 80%;
    border-top: solid 1px #a7a4a4;
    border-bottom: solid 1px #a7a4a4;
    text-align: center;
    font-family: "Varela Round", sans-serif;
    font-size: 12px;
    line-height: 19px;
    color: #222222;
    font-weight: 600;

}
.sidebarItem>img{
    margin-top:15px;
    width: 200px;
}
.sidebarItem >p{
    padding:30px;
}
.sidebarList{
    list-style-type: none;
    margin-bottom: 30px;
}
.sidebarListItem{
    display: inline-block;
    width: 50%;
    margin-top: 15px;
    cursor: pointer;
}
.sidebarSocial{
    margin-top: 15px;
  width: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sidebarIcon{
    font-size:16px;
    margin-left:10px;
}
