
  
  .contain {
    background-color: #eee;
    max-width: 1170px;
    margin-left: auto;
    margin-right: auto;
    padding: 1em;
    margin-top: 100px;
    margin-bottom: 100px;
    margin: 50px;
  }
  
  div.form {
    background-color: #eee;
  }
  .contact-wrapper {
    margin: auto 0;
  }
  
  .submit-btn {
    float: left;
  }
  .reset-btn {
    float: right;
  }
  
  .form-headline:after {
    content: "";
    display: block;
    width: 10%;
    padding-top: 10px;
    border-bottom: 3px solid blue;
  }
  
  .highlight-text {
    color: blue;
  }
  
  .hightlight-contact-info {
    font-weight: 700;
    font-size: 22px;
    line-height: 1.5;
  }
  
  .highlight-text-grey {
    font-weight: 500;
  }
  
  .email-info {
      margin-top: 20px;
  }
  
  
  
  .required-input {
    color: black;
  }
  
  
  h3,
  ul {
    margin: 0;
  }
  
  h3 {
    margin-bottom: 1rem;
  }
  
  .form-input:focus,
  textarea:focus{
    outline: 1.5px solid blue;
  }
  
  .form-input,
  textarea {
    width: 100%;
    border: 1px solid #bdbdbd;
    border-radius: 5px;
    padding-bottom: 10px;
  }
  
  .wrapper > * {
    padding: 1em;
  }
  
  
  ul {
    list-style: none;
    padding: 0;
  }
  
  .contacts {
    color: #212d31;
  }
  
  .form {
    background: #fff;
  }
  
  .form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }
  .form .label {
    display: block;
  }
  .form .p {
    margin: 0;
  }
  
  .full-width {
    grid-column: 1 / 3;
  }
  
  button,
  .submit-btn,
  .form-input,
  textarea {
    padding: 1em;
  }
  
  button, .submit-btn {
    background: transparent;
    border: 1px solid blue;
    color: blue;
    border-radius: 15px;
    padding: 5px 20px;
    text-transform: uppercase;
  }
  button:hover, .submit-btn:hover,
  button:focus , .submit-btn:focus{
    background: blue;
    outline: 0;
    color: #eee;
  }
  .error {
    color: blue;
  }
  
  