
.posts{
    
    display: flex;
    flex-wrap: wrap;
    margin: 5px;
    justify-content: space-between;
    margin-left: 80px;
    text-transform: capitalize;
    margin-top: 20px;
}